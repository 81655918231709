<template>
    <v-container>

      <!-- <v-card width="auto" class="mb-8" elevation="1" max-width="374">
        <v-toolbar
          flat
        >
        <v-toolbar-title tag class="primary--text">Station</v-toolbar-title>
        </v-toolbar>
        <v-select
        v-on:change="selectValueStation"
        :items="itemStation"
        label="Station"
        class="mx-4"
        required
        outlined 
        dense
        ></v-select>
      </v-card> -->

    <v-data-table
      :headers="theadParamList"
      :items="itemsWithIndexParam"
      :search="search"
      class="elevation-2"
      fixed-header
      height="auto"
      :loading="this.loadingTable"
      :loading-text="this.loadingText"
    >

      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title tag class="primary--text">List of Parameter</v-toolbar-title>
  
        </v-toolbar>

        <v-card-title>
          <v-row>
            <v-col>
              <v-select
              v-on:change="selectValueStation"
              :items="itemStation"
              label="Station"
              class="mx-4"
              required
              outlined 
              dense
              prepend-inner-icon="mdi-store-24-hour"
              ></v-select>
            </v-col>
            <v-col>
              <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details
              outlined
              dense
              autocomplete="off"
              class="shrink"
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
            </v-col>
          </v-row>
          
          
        </v-card-title>
      </template>

      <!-- <template v-slot:[`item.actions`]="props">
        <template>
          <v-icon
            small
            class="mr-2 green--text"
            @click="editStationItem(props.item); scrollTo();"
            >mdi-pencil</v-icon
          >
          <v-icon
            small
            class="red--text"
            text
            @click="deleteStation(props.item.stationId)"
            >mdi-delete</v-icon
          >
        </template>
      </template> -->


  </v-data-table>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
        editDialog: false,
        dialogDelete: false,
        delSelected: '',

        itemStation: [],
        valStation: null,

        dataStation: [],

        loadingTable: false,
        loadingText: "",

        theadParamList: [
          { text: 'No.', sortable: false, value: 'index' },
          { text: 'Parameter', sortable: false, value: 'fullname' },
          { text: 'Acronym', sortable: false, value: 'acronym' },
          { text: 'Unit', sortable: false, value: 'unit' },
          // { text: 'Action', sortable: false, value: 'actions' }
        ],
        tbodyParamList: [],
        search: '',

  }),

  methods: {

    getListStation(){
        axios.get('https://temptunnel1.shaz.my/api/cms/stations', {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {
            console.log(response.data);
            this.itemStation = [];

            for (let i = 0; i < response.data.length; i++) {
              this.itemStation.push(response.data[i].stationId + " - " + response.data[i].location);
            }

            this.dataStation = response.data;
            
        })
        .catch(error => {
            console.log(error);
        })
    },

    // Get value from Selected Station
    selectValueStation: function(e) {

        this.loadingTable = true;
        this.loadingText = "Data Loading... Please wait";

        // console.log(e);
        this.valStation = e.split(" - ")[0];

        this.tbodyParamList = [];
        this.getAllParams(this.valStation)
    },


    getAllParams(station){
        axios.get("https://temptunnel1.shaz.my/api/cms/params", {
            headers: {
                Authorization: 'Bearer ' + this.$store.getters.user.token,
            },
        })
        .then((response) => {

            let data = response.data;
            // this.tbodyParamList = response.data
            // console.log(this.tbodyParamList)

            console.log(data);

            let allDataStationSelected = [];
            let dataDisplayTable = [];

            for (let k = 0; k < this.dataStation.length; k++) {
              if (this.dataStation[k].stationId == station) {
                allDataStationSelected.push(this.dataStation[k]);
              }
            }

            console.log(allDataStationSelected);

            let keyData = Object.keys(allDataStationSelected[0])
            // console.log(keyData);

            for (let i = 0; i < data.length; i++) {
              for (let x = 0; x < keyData.length; x++) {
                // console.log(keyData[x].split("_ACTIVE")[0]);
                if (data[i].reading == allDataStationSelected[0][keyData[x]] || data[i].reading == "_"+allDataStationSelected[0][keyData[x]]) {
                  // console.log(data[i].reading);
                  dataDisplayTable.push(data[i]);
                }
              }
            }

            console.log(dataDisplayTable);
            this.tbodyParamList = dataDisplayTable
            this.loadingTable = false;
            this.loadingText = "";
        })
        .catch((error) => {
            console.log(error)
        })
    },

  },

  computed: {
    itemsWithIndexParam() {
      return this.tbodyParamList.map((tbodyParamList, index) => ({
        ...tbodyParamList,
        index: index + 1,
      }));
    }
  },

  mounted() {
    this.getListStation();
    // this.getAllParams()
  }
}
</script>

<style lang="scss" scoped>


</style>